import config from "../config";

export default class AuthServices {

  static signIn = (email: string, password: string) => {
    return fetch(`${config.VITE_BACKEND_HOST}/auth/login`, {
      method: "POST",
      headers: {"Content-Type": "application/json",},
      body: JSON.stringify({email, password,}),
    }).then((res) => res.json());
  }
}
