import {Route, Routes} from "react-router-dom";
import ProductsLanding from "./ProductsLanding";
import {ROUTES} from "../../utils/consts/router";

function Products() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<ProductsLanding/>}/>
        <Route path={ROUTES.PRODUCTS_EDIT} element={<div>Product</div>}/>
        <Route path={ROUTES.PRODUCTS_CREATE} element={<div>Add Product</div>}/>
      </Routes>
    </div>
  )
}

export default Products;