import {ChakraProvider} from "@chakra-ui/react";
import {RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import {dashboardRouter} from "./router/Router";
import Error404 from "./views/errors/Error404";

function App() {

  return (
    <Provider store={store}>
        <ChakraProvider>
          <RouterProvider router={dashboardRouter} fallbackElement={<Error404/>}/>
        </ChakraProvider>
    </Provider>
  )
}

export default App
