import {useEffect, useState} from "react";
import Product from "../../models/product";
import {Link} from "react-router-dom";
import ProductServices from "../../services/ProductServices";
import {styled} from "@chakra-ui/react";

const Content = styled("div", {
  baseStyle: {
    padding: "20px",
  }
})

function ProductsLanding() {

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ProductServices.getProducts().then((products) => {
      setProducts(products);
    }).finally(() => setLoading(false));
  }, []);

  return (
    <Content>
      <h1>Products</h1>
      {loading ? (<p>Loading...</p>) : (
        products.map((product) => (
          <div key={product.id}>
            <Link to={`/products/${product.id}`}>
              <div style={{paddingTop: "10px"}}/>
              <div>id</div>
              <div>{product.id}</div>
              <div style={{paddingTop: "10px"}}/>
              <div>name</div>
              <div>{product.name}</div>
              <div style={{paddingTop: "10px"}}/>
              <div>description</div>
              <div>{product.description}</div>
              <div style={{paddingTop: "10px"}}/>
              <div>price</div>
              <div>{product.price}</div>
            </Link>
          </div>
        ))
      )}
    </Content>
  );
}

export default ProductsLanding;