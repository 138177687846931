import {ReactNode} from "react";
import PageTemplate from "./PageTemplate";
import ProtectedPage from "../router/ProtectedPage";
import User from "../models/user";

interface ProtectedPageTemplateProps {
  children: ReactNode;
}

function ProtectedPageTemplate({
  children
}: ProtectedPageTemplateProps) {

  const accessDashboard = ({user}: { user: User | undefined }) => user?.permissions.includes("dashboard") || false;

  return (
    <ProtectedPage access={accessDashboard}>
      <PageTemplate>
        {children}
      </PageTemplate>
    </ProtectedPage>
  );
}

export default ProtectedPageTemplate;