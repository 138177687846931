export const ROUTES = {
  HOME: '/*',
  HOME_CHILD: '/',
  PRODUCTS: '/products/*',
  PRODUCTS_CHILD: '/products',
  PRODUCTS_CREATE: '/products/create',
  PRODUCTS_EDIT: '/products/edit/:id',
  PROFILE: '/profile',
  ERROR_404: "*/*",
  DEFAULT_SITE: "https://tolnkee.com"
}
