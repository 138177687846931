import {NavigationElement} from "../../../types/navigation";
import {ROUTES} from "../../../utils/consts/router";
import {RxDashboard} from "react-icons/rx";
import {styled} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {BsShop} from "react-icons/bs";

const Navigation = styled("div", {
  baseStyle: {
    marginTop: "100px",
  }
})

const Element = styled("div", {
  baseStyle: {
    height: "40px",
    marginLeft: "30px",
    marginRight: "20px",
    display: "flex",
    color: "#fff",
    width: "auto",
    alignItems: "center",
    fontFamily: "Lato",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "25px",
    paddingBottom: "25px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      borderRadius: "7px",
    },
    transition: "all 0.3 ease-in-out",
  }
})

const Icon = styled("div", {
  baseStyle: {
    margin: 5,
  }
})

const navigationElements: NavigationElement[] = [
  {
    title: "Dashboard",
    icon: <RxDashboard style={{width: 25, height: 25}}/>,
    path: ROUTES.HOME_CHILD,
  },
  {
    title: "Produits",
    icon: <BsShop style={{width: 25, height: 25}}/>,
    path: ROUTES.PRODUCTS_CHILD,
  }
]

function OverlayNavigation() {

  const navigate = useNavigate();

  return (
    <Navigation>
      {navigationElements.map((element, index) => {

        const current = window.location.pathname === element.path;

        return (
          <Element key={index} onClick={() => navigate(element.path)}>
            <Icon style={
              current ? {color: "#FF4646"} : {color: "#939393"}
            }>
              {element.icon}
            </Icon>
            <div style={
              current ? {color: "#FF4646"} : {color: "#000000"}
            }>{element.title}</div>
          </Element>
        )
      })}
    </Navigation>
  )
}

export default OverlayNavigation;