import config from "../config";

export default class ProductServices {

  static getProducts = () => {
    return fetch(`${config.VITE_BACKEND_HOST}/products/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  }
}
