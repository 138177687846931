import ProtectedPageTemplate from "../ProtectedPageTemplate";
import {Route, Routes} from "react-router-dom";
import Products from "../products/Products";
import {ROUTES} from "../../utils/consts/router";
import HomeLanding from "./HomeLanding";
import Profile from "../profile/Profile";

function Home() {

  return(
    <ProtectedPageTemplate>
      <Routes>
        <Route path={ROUTES.HOME_CHILD} element={<HomeLanding/>}/>
        <Route path={ROUTES.PRODUCTS} element={<Products/>}/>
        <Route path={ROUTES.PROFILE} element={<Profile/>}/>
      </Routes>
    </ProtectedPageTemplate>
  )
}

export default Home;