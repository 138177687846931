import {styled} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/consts/router";
import OverlayNavigation from "./navigation/OverlayNavigation";
import OverlayProfile from "./OverlayProfile";
import Logo from "../../assets/img/tolnkee_logo_long_bo.png";

const Sidebar = styled("div", {
  baseStyle: {
    width: "250px",
    height: "100vh",
    backgroundColor: "#FFFFFF",
  }
})

const Header = styled("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    cursor: "pointer",
  }
})

function OverlaySidebar() {

  const navigate = useNavigate();

  return (
    <Sidebar>
      <Header onClick={() => navigate(ROUTES.HOME_CHILD)}>
        <img alt="Logo" src={Logo} width={170}/>
      </Header>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100% - 70px)",
      }}>
        <OverlayNavigation/>
        <OverlayProfile/>
      </div>
    </Sidebar>
  )
}

export default OverlaySidebar;