import {ReactNode} from "react";
import OverlaySidebar from "../components/overlay/OverlaySidebar";
import {styled} from "@chakra-ui/react";

const Page = styled("div", {
  baseStyle: {
    display: "flex",
    backgroundColor: "#F5F5F5",
  }
})

interface PageTemplateProps {
  children: ReactNode;
}

function PageTemplate({
  children
}: PageTemplateProps) {

  return (
    <Page>
      <OverlaySidebar/>
      {children}
    </Page>
  )
}

export default PageTemplate;